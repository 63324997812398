// https://router.vuejs.org/api/interfaces/routeroptions.html

export default {
    scrollBehavior(to, from, savedPosition) {
        // this is empty in order to disable nuxt scroll-persist.
        // we handle it ourself in app.vue
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            };
        } else if (from.path == to.path || to.hash.length > 0) {
            return null;
        }
    }

    /**
     * disable all routes when not in development,
     * used as the coming-soon-teaser-page
     */
    // ...(process.env.NODE_ENV !== 'development'
    //   ? {
    //       routes: _routes => [
    //         {
    //           name: 'index',
    //           path: '/',
    //           component: () => import('~/pages/index.vue'),
    //         },
    //       ],
    //     }
    //   : {}),
}